.menu {
    margin: 0%;
    padding: 0%;
    border: 0%;
    margin-top: 1.2%;
    margin-bottom: 1.2%;
}

.menu-container {
    padding-left: auto;
    padding-right: auto;
}

.custom-menu-card {
    margin-bottom: 10rem;
}

.custom-line-progress {
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;

}

.custom-menu-card-2 {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.custom-menu-card-21 {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.custom-menu-card-2:hover {
    cursor: pointer;
}

.custom-cprogress-container {
    /* padding-top: 10%; */
    width: 70%;
}

.cprogress {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.progress-heading {
    color: ghostwhite;
    font-size: 0.8rem;
    margin-left: 0.7rem;
    margin-bottom: 0%;
}

.progress-hard {
    margin-bottom: 0.5rem;
}