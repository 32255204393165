@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Open+Sans&display=swap');

.an:hover {
    cursor: pointer;
}

.an {
    font-family: 'DM Sans', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: large;
}


.color-nav {
    margin-bottom: 2rem;
    background-color: #291E29;
}

.dropdown-toggle::after {
    display: none !important;
}