.title {
  font-size: large;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.custom-header {
  height: 82%;
}

.accordion-button {
  /* background-color: inherit !important; */
  background-color: #1a1a1a !important;
  border: none;
}

.progressbar {
  justify-content: center;
}

.accordion-button:focus {
  box-shadow: none;
  background-color: #1a1a1a;
}


.dropdown {
  box-shadow: none;
}

.accordion-body {
  background-color: #1a1a1a;
  height: fit-content;
}

.link {
  text-decoration: none;
  color: ghostwhite;
  font-size: 120%;
}

.cls {
  margin-top: 0.15rem;
  margin-bottom: 0rem;
}

.table-back {
  background-color: #262d33;
}

.accordion {
  margin: 0.7%;
  width: 70rem;
}



.zucc {
  color: rgb(0, 183, 255);
  margin-left: 10px;
}

.zucc:hover {
  cursor: pointer;
  color: skyblue;
}

.pg-top {

  margin-bottom: 0.1rem;
}

.pg-bottom {
  margin-top: 0.1rem;
}

/* .accordion-item {
  height: 2rem;
} */