.heading {
  font-size: 1.4rem;
  /* font-variant: small-caps; */
  color: ghostwhite;
  text-align: left;
}

.custom-table {
  background-color: #1A2228;
}

.custom-button {
  box-shadow: none;
  /* border-radius: 20%; */
}


/* 
.gridElementFullBox {
  color: white;
  background-color: rgb(48, 183, 255);
  border-radius: 3px;
} */

.gridElementHollowBox {
  color: grey;
}

.gridElementCreateListSection {
  background-color: rgb(44, 43, 43);
}

.gridElementHollowBox:hover {
  background-color: inherit;
}

.button {
  box-shadow: none;
}



.gridElementDropdownWrapper {
  padding: 10%;
}

.gridElementCreateListTextField {
  background-color: ghostwhite;
}

.desc {
  text-decoration: none;
  font-size: large;
  color: ghostwhite;
}