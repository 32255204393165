@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.menu {
    margin-right: auto;
    margin-left: auto;
}

.menu-heading {
    color: ghostwhite;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    justify-content: center;
    text-align: center;
    font-size: 2.5rem;

}

.custom-menu-card {
    box-shadow: none;
    background-color: #262d33;
}

.menu-container {
    justify-content: center;
    background-color: #1B1B1B;
    width: auto;
    box-shadow: none;
    margin-bottom: 1.5rem;
}

.custom-menu {
    color: White;
    justify-content: center;
    font-size: x-large;
}

.welcome {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.7rem;
}

.menu {
    margin: 0%;
    padding: 0%;
    border: 0%;
    margin-top: 1.7%;
}

.custom-menu-card {
    margin-bottom: 10rem;
}

.custom-line-progress {
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;

}

.custom-menu-card-2 {
    margin-top: 2rem;
}

.custom-cprogress-container {
    /* padding-top: 10%; */
    width: 70%;
}

.cprogress {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.progress-heading {
    color: ghostwhite;
    font-size: 0.8rem;
    margin-left: 0.7rem;
    margin-bottom: 0%;
}

.progress-hard {
    margin-bottom: 0.5rem;
}