@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@500&display=swap');

form {
    background-color: ghostwhite;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.formContainer {
    position: relative;
    min-height: 100vh;
}

.loginContainer {
    width: 25rem;
}

.myForm {
    position: absolute;
    height: 100%;
    width: 100%;
}

.loginHead {
    font-family: 'Public Sans', sans-serif;
    font-size: 200%;
    color: black;
    justify-content: center;
    text-align: center;
}

.ana {
    text-decoration: underline;
}

.ana:hover {
    cursor: pointer;
}