.ListPageWrapper {
  display: flex;
  width: 80%;
  margin: auto;
}

.myListLeftSection {
  width: 35%;
  margin-top: 2rem;
  border-radius: 20px;
  color: azure;
}

.myListRightSection {
  width: 60%;
  margin-top: 2rem;
}

.myListItems {
  background-color: black;
}

.myListHeader {
  font-size: 19px;
  border: 1px solid grey;
  padding: 0.7rem;
  font-weight: 700;
}

.myListItem {
  font-size: 17px;
  border: 1px solid grey;
  padding: 0.4rem;
}

.myListItem:hover {
  cursor: pointer;
  background-color: grey;
}

.myListRightSectionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(56, 56, 56);
  width: 100%;
  padding: 0.7rem;
  font-size: 20px;
  color: beige;
  border: 1px solid grey;
}

.myListRightSection {
  margin-left: 1rem;
}

.myListDeleteButton {
  color: red;
  border: 2px solid rgb(255, 0, 0);
}

.myListRightSectionItemWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid grey;
}

.myListRightSectionProblemTitleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myListCheckBox {
  margin-right: 10px;
}

.myListRightSectionProblemTitle {
  margin-left: 10px;
  font-size: 17px;
}

.myListRightSectionItemWrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.myListDeleteIcon {
  color: red;
}

.myListDeleteIcon:hover {
  cursor: pointer;
  color: rgb(255, 90, 90);
}

.myListRightSectionProblemTitle {
  color: rgb(0, 162, 255);
  font-size: 19px;
}

.myListProblemItemChecker {
  color: rgba(88, 245, 16, 0.719);
}

.myListLeftSectionBottom>Form {
  background-color: black;
  padding: 1rem;
  border: 1px solid grey;
}

.newListNameField {
  background-color: rgb(36, 36, 36);
  color: white;
}

.myListLeftSectionItems {
  border: 1px solid grey;
  padding: 10px;
}

.myListLeftSectionItems:hover {
  background-color: rgb(145, 197, 218);
  cursor: pointer;
}

.myListLeftSectionItemsColored {
  border: 1px solid grey;
  padding: 10px;
  background-color: rgb(0, 183, 255);
}

.custom-list-elements:hover {
  background-color: #2a9fd6;
}